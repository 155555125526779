export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/about": [11,[3]],
		"/about/directors-message": [12,[3]],
		"/academics/childrens-corner/competitions": [13],
		"/academics/childrens-corner/diploma/exam-details": [14],
		"/academics/childrens-corner/text-books": [15],
		"/academics/teaching-aids/TTC": [16],
		"/academics/teaching-aids/teaching-resources": [17],
		"/latest": [18,[4]],
		"/latest/[type]/[id]": [19,[4]],
		"/ovbs/[year]": [20],
		"/(resources)/resources/canonical-resources": [6,[2]],
		"/(resources)/resources/gallery": [7,[2]],
		"/(resources)/resources/lectionary": [8,[2]],
		"/(resources)/resources/lectionary/[id]": [9],
		"/(resources)/resources/liturgical-calendar": [10,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';